import React from 'react';
import { CssBaseline, Container } from '@material-ui/core';

import {
  Switch,
  Route,
} from "react-router-dom";

import MyAppBar from "./Components/AppBar/AppBar";
import ProfileScreen from "./Screens/ProfileScreen/ProfileScreen";
import NotesScreen from "./Screens/NotesScreen/NotesScreen";
import VideoScreen from './Screens/VideoScreen/VideoScreen';
import MyFooter from './Components/Footer/Footer';

function Main() {
  return (
    <>
      <MyAppBar
        heading="Sankalp"
        tabs={[
          { label: "Profile", to: "/" },
          { label: "Notes", to: "/notes" },
          { label: "Videos", to: "/videos" },
        ]}
      />
      <Switch>
        <Route exact path="/"><ProfileScreen /></Route>
        <Route exact path="/notes"><NotesScreen /></Route>
        <Route exact path="/videos"><VideoScreen /></Route>
      </Switch>
      <MyFooter 
        followMeHeading="Follow me on:"
        links={[
          {icon:"LinkedInIcon", link:"https://www.linkedin.com/in/sai-sankalp-reddy-0534ab15b"},
          {icon:"YouTubeIcon", link:"https://www.youtube.com/channel/UCG9c8BA9zHhPtSQWjg9T1tA"},
          {icon:"TwitterIcon", link:"https://twitter.com/SaiSank09329256?s=08"},
          {icon:"InstagramIcon", link:"https://www.instagram.com/sai_sankalp_reddy?r=nametag"},
        ]}
      />
    </>
  )
}

function App() {
  return (
    <React.Fragment>
      <CssBaseline style={{ backgroundColor: "white" }} />
      <Switch>
        <Route><Main /></Route>
      </Switch>
    </React.Fragment>
  );
}

export default App;
