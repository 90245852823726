import React from 'react';
import { Tab } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const StyledTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        color: '#000',
        fontWeight: 'regular',
        fontSize: 15,
        marginRight: 1,
        minWidth: 72,
        '&:focus': {
            opacity: 1,
        },
    },
}))((props) => <Tab disableRipple {...props} />);
