import React from 'react';
import PropTypes from 'prop-types';

import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';

const getIcon = (image) => {
    switch (image) {

        // Footer icons
        case "LinkedInIcon":
            return <LinkedInIcon/>
        case "YouTubeIcon":
            return <YouTubeIcon/>
        case "TwitterIcon":
            return <TwitterIcon/>
        case "InstagramIcon":
            return <InstagramIcon/>
    }
}

const IconProvider = (props) => {
    return getIcon(props.icon)
};

IconProvider.propTypes = {};

export default IconProvider;