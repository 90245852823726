import React from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import ImageProvider from '../../../assets/Images/ImageProvider';

const TechnologiesSection = (props) => {
    return (
        <Grid item container justify="space-between" xs={12}>
            <SectionHeading heading={props.data.heading} />
            {props.data.TechnologiesList.map(technology => {
                return (
                    <Grid item container direction={(technology.orientation === "right") ? "row" : "row-reverse"} style={{ textAlign: "center" }}>
                        <Technology technology={technology}/>
                        <PlatformList platforms={technology.platforms}/>
                    </Grid>
                );
            })}
            <Box p={6}></Box>
        </Grid>
    );
}

export default TechnologiesSection;

const SectionHeading = (props) => {
    return (
        <Grid item xs={12} style={{ textAlign: "center" }}>
            <Box p={6} pt={12}>
                <Typography variant="h3">{props.heading}</Typography>
            </Box>
        </Grid>
    )
}

const Technology = (props) => {
    return (
        <Grid xs={6}>
            <Box style={{ height: 400, display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                <Typography variant="h5">{props.technology.title}</Typography>
            </Box>
        </Grid>
    )
}

const PlatformList = (props) => {
    return (
        <Grid xs={6}>
            <Box style={{ height: 400, display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: "#F7F7F7" }}>
                {props.platforms.map(platform => {
                    return <Platform platform={platform}/>
                })}
            </Box>
        </Grid>
    )
}

const Platform = (props) => {
    return (
        <Box style={{ display: "flex", flexDirection: 'row', alignItems: 'center', width: 400, padding: 20 }}>
            <ImageProvider image={props.platform.image} width={82} height={82} />
            <Typography><Box pl={3}>{props.platform.title}</Box></Typography>
        </Box>
    )
}