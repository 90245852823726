import React from 'react';
import PropTypes from 'prop-types';

// common assets
import Lantern from './CommonAssets/landing/Lantern.png';
import Person from './CommonAssets/landing/Person.png';
import Room from './CommonAssets/landing/Room.png';

// profile assets: experiences
import AirasiaLogo from './ProfileScreenAssets/experience/airasia_logo.png';
import NokiaLogo from './ProfileScreenAssets/experience/nokia_logo.png';

// profile assets: technologies
import AndroidLogo from './ProfileScreenAssets/technologies/android_logo.png';
import AppleLogo from './ProfileScreenAssets/technologies/apple_logo.png';
import DockerLogo from './ProfileScreenAssets/technologies/docker_logo.png';
import FlaskLogo from './ProfileScreenAssets/technologies/flask_logo.png';
import KubernetesLogo from './ProfileScreenAssets/technologies/kubernetes_logo.png';
import MongoDBLogo from './ProfileScreenAssets/technologies/mongo_db_logo.png';
import NodeJSLogo from './ProfileScreenAssets/technologies/node_js_logo.png';
import ReactLogo from './ProfileScreenAssets/technologies/react_logo.png';
import ScikitLearnLogo from './ProfileScreenAssets/technologies/scikit_learn_logo.png';
import TensorflowLogo from './ProfileScreenAssets/technologies/tensorflow_logo.png';

const getImage = (image) => {
    switch (image) {

        // common assets
        case "Lantern":
            return Lantern
        case "Person":
            return Person
        case "Room":
            return Room

        // profile assets: experiences
        case "AirasiaLogo":
            return AirasiaLogo
        case "NokiaLogo":
            return NokiaLogo

        // profile assets: technologies
        case "AndroidLogo":
            return AndroidLogo
        case "AppleLogo":
            return AppleLogo
        case "DockerLogo":
            return DockerLogo
        case "FlaskLogo":
            return FlaskLogo
        case "KubernetesLogo":
            return KubernetesLogo
        case "MongoDBLogo":
            return MongoDBLogo
        case "NodeJSLogo":
            return NodeJSLogo
        case "ReactLogo":
            return ReactLogo
        case "ScikitLearnLogo":
            return ScikitLearnLogo
        case "TensorflowLogo":
            return TensorflowLogo
    }
}

const ImageProvider = (props) => {
    const image = getImage(props.image)

    return (
        <img src={image} style={{ width: props.width, height: props.height }} alt={props.image} />
    )
};

ImageProvider.propTypes = {};

export default ImageProvider;