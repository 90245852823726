const initialState = {
    ui: {
        landing: {
            headingOne: "Hi I Am",
            headingTwo: "Sankalp Reddy",
            subtitle: "I am a full stack developer",
            buttonText: "CONTACT ME",
        },
        skills: {
            heading: "Skills",
            skillsList: [
                {
                    title: "Full Stack Development",
                    description: "Proficient with Web Development, Native Mobile App development (android and ios), Progresive Web Apps, NoSQL and SQL technologies and Low level design."
                },
                {
                    title: "DevOps",
                    description: "Worked on Kubernetes, Docker, Gitlab CI/CD, reverse proxys etc. Have deployed server instances in kubernetes, worked on ingress controllers, replication sets, stateful sets etc. Have managed and deployed server instances for projects."
                },
                {
                    title: "Data Science",
                    description: "I have worked on various projects on data science in college and will continue working on them as personal projects. I am comfortable with tensorflow and tensorflow.js . I am also familiar with distributed training and data pipelines. "
                },
                {
                    title: "Mobile Development",
                    description: "Been working on mobile development projects for 3 years. Started with native android development for one year and then switched to react native for one year. Was introduced to ios in Airasia. Have worked on it for 5 months."
                },
                {
                    title: "Frontend Development",
                    description: "I am good at React as its my primary tool for buiding web applications. I am well versed with state management libraries like Redux and MobX. I am also familiar with electron for desktop applications."
                },
                {
                    title: "Backend Development",
                    description: "I am familiar with Node.js(express,sails,kraken), flask, php for REST APIs. I have worked with MOngoDB and SQL databases in my projects."
                },
                
            ]
        },
        experience: {
            heading: "Experience",
            ExperienceList: [
                {
                    title: "AirAsia - iOS Developer intern",
                    logo: { name:"AirasiaLogo", width:254, height:160 },
                    description: "Worked on the ios AirAsia ASEAN super app. Contributed on features like unlimited subscription, update nudge etc. First experience of working on a product with a large user base. Learnt a lot on agile practices, writing production code, Low level design and best practices.",
                    date: "January 4 - Present"
                },
                {
                    title: "Nokia - SDE intern",
                    logo: { name:"NokiaLogo", width:254, height:160 },
                    description: "Worked with sales team to build applications to automate sales planning processes and recommend actions to increases efficiency of operations.",
                    date: "February 20 - July 18"
                },
            ]
        },
        technologies: {
            heading: "Technologies",
            TechnologiesList: [
                {
                    title: "Mobile  Technologies",
                    orientation: "right",
                    platforms: [
                        {
                            image: "ReactLogo",
                            title: "React and React Native"
                        },
                        {
                            image: "AndroidLogo",
                            title: "Android Native"
                        },
                        {
                            image: "AppleLogo",
                            title: "iOS Native"
                        },
                    ]
                },
                {
                    title: "Web Technologies",
                    orientation: "left",
                    platforms: [
                        {
                            image: "MongoDBLogo",
                            title: "MongoDB"
                        },
                        {
                            image: "NodeJSLogo",
                            title: "Node.js ( express, sails )"
                        },
                        {
                            image: "FlaskLogo",
                            title: "Flask"
                        },
                    ]
                },
                {
                    title: "DevOps",
                    orientation: "right",
                    platforms: [
                        {
                            image: "KubernetesLogo",
                            title: "Kubernetes"
                        },
                        {
                            image: "DockerLogo",
                            title: "Docker"
                        },
                    ]
                },
                {
                    title: "Data Science",
                    orientation: "left",
                    platforms: [
                        {
                            image: "TensorflowLogo",
                            title: "Tensorflow And Tensorflow.js"
                        },
                        {
                            image: "ScikitLearnLogo",
                            title: "Scikit learn"
                        },
                    ]
                },
            ]
        }
    }
}

function reducer(state, action) {
    switch (action.type) {
        case 'increment':
            return { count: state.count + 1 };
        case 'decrement':
            return { count: state.count - 1 };
        default:
            throw new Error();
    }
}

export {
    initialState as ProfileScreenInitialState,
    reducer as ProfileScreenReducer
}