import React, { useReducer } from 'react';
import { Grid } from '@material-ui/core';
import LandingSection from './Sections/LandingSection';
import SkillsSection from './Sections/SkillsSection';
import ExperienceSection from './Sections/ExperienceSection';
import TechnologiesSection from './Sections/TechnologiesSection';
import { ProfileScreenReducer, ProfileScreenInitialState } from './ProfileScreenReducer';

const ProfileScreen = () => {
    const [profileState, profileDispatch] = useReducer(ProfileScreenReducer, ProfileScreenInitialState)
    return (
        <Grid container justify="center" style={{ backgroundColor: 'white' }} xs={12}>
            <LandingSection data={profileState.ui.landing} />
            <SkillsSection data={profileState.ui.skills} />
            <ExperienceSection data={profileState.ui.experience} />
            <TechnologiesSection data={profileState.ui.technologies} />
        </Grid>
    );
};

ProfileScreen.propTypes = {};

export default ProfileScreen;