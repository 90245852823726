const initialState = {
    ui: {
        landing: {
            heading: "Coming Soon",
            subtitle: "My notes on various topics will be posted here",
        },
    }
}

function reducer(state, action) {
    switch (action.type) {
        case 'increment':
            return { count: state.count + 1 };
        case 'decrement':
            return { count: state.count - 1 };
        default:
            throw new Error();
    }
}

export {
    initialState as NotesScreenInitialState,
    reducer as NotesScreenReducer
}