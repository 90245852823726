const initialState = {
    ui: {
        landing: {
            heading: "Coming Soon",
            subtitle: "Videos on tutorials will be posted",
            buttonText: "Open Channel",
            buttonHyperLink: "https://www.youtube.com/channel/UCG9c8BA9zHhPtSQWjg9T1tA",
        },
    }
}

function reducer(state, action) {
    switch (action.type) {
        case 'increment':
            return { count: state.count + 1 };
        case 'decrement':
            return { count: state.count - 1 };
        default:
            throw new Error();
    }
}

export {
    initialState as VideoScreenInitialState,
    reducer as VideoScreenReducer
}