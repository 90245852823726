import React from 'react';
import { Grid, Typography, Hidden } from '@material-ui/core';
import ImageProvider from '../../../assets/Images/ImageProvider';

const LandingSection = (props) => {
    return (
        <>
            <LanternImage />
            <HeadingAndDescription data={props.data} />
            <BothStockImages />
            <OneStockImage />
        </>
    );
}

export default LandingSection;

const LanternImage = (props) => {
    return (
        <Grid item container justify="center" xs={12}>
            <ImageProvider image={"Lantern"} width={87} height={185} />
        </Grid>
    )
}

const HeadingAndDescription = (props) => {
    return (
        <Grid item container xs={12} style={{ margin: 80 }}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
                <Typography variant="h2">{props.data.headingOne}</Typography>
                <Typography variant="h2">{props.data.headingTwo}</Typography>
            </Grid>
            <Grid item xs={12} style={{ margin: 20, textAlign: "center" }}>
                <Typography>{props.data.subtitle}</Typography>
            </Grid>
        </Grid>
    )
}

const BothStockImages = (props) => {
    return (
        <Hidden xsDown>
            <Grid item container justify="space-between" xs={9}>
                <ImageProvider image={"Person"} width={233} height={280} />
                <ImageProvider image={"Room"} width={188} height={213} />
            </Grid>
        </Hidden>
    )
}

const OneStockImage = (props) => {
    return (
        <Hidden smUp>
            <Grid item container justify="center" xs={12}>
                <ImageProvider image={"Person"} width={233} height={280} />
            </Grid>
        </Hidden>
    )
}
