import React, { useEffect } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import IconProvider from '../../assets/Icons/IconProvider';

const MyFooter = (props) => {
    return (
        <Grid container alignItems="center" style={{ width: '100%', height: 78, backgroundColor: '#F8F9FA' }}>
            <Grid item xs={1}>
                <Box textAlign="center">
                    <Typography>{props.followMeHeading}</Typography>
                </Box>
            </Grid>
            <Grid item xs={10}>
                <Grid container xs={2} justify="space-around" >
                    {
                        props.links.map(link => {
                            return (
                                <Grid item>
                                    <span onClick={() => window.open(link.link, "_blank")}>
                                        <IconProvider icon={link.icon} />
                                    </span>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Grid>
        </Grid>
    );
};

MyFooter.propTypes = {};

export default MyFooter;