import React, { useEffect } from 'react';
import { Link, withRouter, useLocation } from "react-router-dom";
import { AppBar, Box, Grid, IconButton, Toolbar, Typography, Hidden } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { StyledTab } from './components/StyledTab';
import { StyledTabs } from './components/StyledTabs';

const MyAppBar = (props) => {
    const [currentTabIndex, setCurrentTabIndex] = React.useState(0);

    let { pathname } = useLocation()

    useEffect(() => setCurrentTabIndex(getIndex(pathname)))

    return (
        <CustomAppBar>
            <CustomToolBar>
                <LeftNavigationItems heading={props.heading} />
                <MiddleNavigationItems currentTabIndex={currentTabIndex} tabs={props.tabs} />
                <RightNavigationItems />
            </CustomToolBar>
        </CustomAppBar>
    );
};

const getIndex = (path) => {
    switch (path) {
        case "/":
            return 0
        case "/notes":
            return 1
        case "/videos":
            return 2
        default:
            return 0
    }
}

const CustomAppBar = (props) => {
    return (
        <AppBar
            position="sticky"
            color="white"
            elevation={0}
        >
            <Box style={{ borderBottom: 1, borderBottomColor: '#F8F9FA' }}>
                {props.children}
            </Box>
        </AppBar>
    )
}

const CustomToolBar = (props) => {
    return (
        <Toolbar>
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
            >
                {props.children}
            </Grid>
        </Toolbar>
    )
}

const LeftNavigationItems = (props) => {
    return (
        <Grid item xs={1}>
            <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Hidden mdUp><IconButton edge="start" color="inherit" aria-label="menu">
                    <MenuIcon />
                </IconButton></Hidden>
                <Typography>{props.heading}</Typography>
            </Box>
        </Grid>
    )
}

const MiddleNavigationItems = (props) => {
    return (
        <Hidden mdDown>
            <Grid item>
                <StyledTabs value={props.currentTabIndex} aria-label="styled tabs example">
                    {
                        props.tabs.map(tab => {
                            return (
                                <Link to={tab.to} style={{ textDecoration: 'none' }}>
                                    <StyledTab label={tab.label} />
                                </Link>
                            )
                        })
                    }
                </StyledTabs>
            </Grid>
        </Hidden>
    )
}

const RightNavigationItems = (props) => {
    return (
        <Grid item xs={1} />
    )
}

MyAppBar.propTypes = {};

export default withRouter(MyAppBar);