import React from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import ImageProvider from '../../../assets/Images/ImageProvider';

const ExperienceSection = (props) => {
    return (
        <Grid container xs={12} justify="center" margin={6}>
            <SectionHeading heading={props.data.heading}/>
            {
                props.data.ExperienceList.map(experience => { return <ExperienceCard experience={experience} /> })
            }
        </Grid>
    )
}

export default ExperienceSection;

const SectionHeading = (props) => {
    return (
        <Grid item xs={12} style={{ textAlign: "center" }}>
            <Box p={6} pt={12}>
                <Typography variant="h3">{props.heading}</Typography>
            </Box>
        </Grid>
    )
}

const ExperienceCard = (props) => {
    return (
        <Grid item style={{ textAlign: "center" }}>
            <Box m={4} width={300}>
                <Box style={{ height: 250, backgroundColor: "#F7F7F7", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <ImageProvider image={props.experience.logo.name} width={props.experience.logo.width} height={props.experience.logo.height} />
                </Box>
                <Typography variant="h6"><Box lineHeight={3.6}>{props.experience.title}</Box></Typography>
                <Typography variant="p"><Box lineHeight={1.6}>{props.experience.description}</Box></Typography>
                <Typography variant="p" style={{ marginTop: 10 }}><Box lineHeight={3.6}>{props.experience.date}</Box></Typography>
            </Box>
        </Grid>
    )
}