import React, { useReducer } from 'react';
import { VideoScreenReducer, VideoScreenInitialState } from './VideoScreenReducer';
import { Grid, Box, Button, Typography } from '@material-ui/core';

import ImageProvider from '../../assets/Images/ImageProvider';

const VideoScreen = () => {
    const [videoState, videoDispatch] = useReducer(VideoScreenReducer, VideoScreenInitialState)
    return (
        <Grid container justify="center" style={{ backgroundColor: 'white' }} xs={12}>
            <LandingSection data={videoState.ui.landing} />
        </Grid>
    );
};

const LandingSection = (props) => {
    return (
        <>
            <Grid item container justify="center" xs={12}>
                <ImageProvider image={"Lantern"} width={87} height={185} />
            </Grid>
            <Grid item container xs={12} style={{ margin: 80 }}>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                    <Typography variant="h2">{props.data.heading}</Typography>
                </Grid>
                <Grid item xs={12} align="center" style={{ margin: 20 }}>
                    <Typography>{props.data.subtitle}</Typography>
                </Grid>
                <Grid item xs={12} align="center">
                    <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={() => window.open(props.data.buttonHyperLink, "_blank")}
                    >
                        {props.data.buttonText}
                    </Button>
                </Grid>
            </Grid>
            <Grid item container justify="space-between" xs={9}>
                <ImageProvider image={"Person"} width={233} height={280} />
                <ImageProvider image={"Room"} width={188} height={213} />
            </Grid>
        </>
    )
}

VideoScreen.propTypes = {};

export default VideoScreen;