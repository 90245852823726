import React from 'react';
import { Grid, Box, Typography } from '@material-ui/core';

const SkillsSection = (props) => {
    return (
        <Grid container xs={12} justify="center" style={{ backgroundColor: '#F8F9FA' }}>
            <SectionHeading heading={props.data.heading}/>
            {
                props.data.skillsList.map(skill => { return <SkillCard skill={skill} /> })
            }
        </Grid>
    );
};

export default SkillsSection;

const SectionHeading = (props) => {
    return (
        <Grid item xs={12} style={{ textAlign: "center" }}>
            <Box pt={6}>
                <Typography variant="h3">{props.heading}</Typography>
            </Box>
        </Grid>
    )
}

const SkillCard = (props) => {
    return (
        <Grid item style={{ textAlign: "center" }}>
            <Box alignItems="center" justifyContent="center" width={250} m={6}>
                <Typography variant="h6"><Box lineHeight={3.6}>{props.skill.title}</Box></Typography>
                <Typography variant="p" style={{}}><Box lineHeight={1.6}>{props.skill.description}</Box></Typography>
            </Box>
        </Grid>
    )
}